<template>
	<div
		class="wrapper"
		:class="{
			['background-' + color]: true,
			active: active
		}"
		v-click-outside="closeBar"
	>
		<div class="search-bar">
			<!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24" @click="toggleBar" class="close-icon">
				<path
					fill="currentColor"
					d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
				/>
			</svg> -->
			<input
				@click="openBar"
				type="text"
				ref="search-text"
				placeholder="Cerca"
				@keypress="onKeyPress"
			/>
			<v-icon class="magnify" @click="onSearchClick" color="white">
				mdi-magnify
			</v-icon>
		</div>
	</div>
</template>
<script>
export default {
	props: ["color"],
	data() {
		return {
			status: false
			//is_writing: false,
		};
	},
	computed: {
		active() {
			return this.status;
		}
	},
	methods: {
		toggleBar() {
			if (this.status) {
				if (this.$refs["search-text"].value != "") {
					this.updateProperty({
						property: "searchTerm",
						value: this.$refs["search-text"].value
					});
				}
			} else {
				this.status = true;
			}
			setTimeout(() => {
				this.$refs["search-text"].focus();
			}, 100);
		},
		openBar() {
			this.status = true;
			setTimeout(() => {
				this.$refs["search-text"].focus();
			}, 100);
		},
		closeBar() {
			if (this.$refs["search-text"]) this.$refs["search-text"].value = "";
			this.status = false;
			/*this.updateProperty({
				property: 'searchTerm',
				value: '',
			});*/
		},
		onKeyPress(e) {
			switch (e.key) {
				case "Enter":
					this.onSearchClick();
					break;
				case "Esc":
				case "Escape":
					this.closeBar();
					break;
			}
		},
		async onSearchClick() {
			if (this.$refs["search-text"].value.length < 3) {
				return;
			}
			//this.is_writing = true;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(
				function() {
					this.updateProperty({
						property: "searchTerm",
						value: this.$refs["search-text"].value
					});
				}.bind(this),
				600
			);
		}
	}
};
</script>
<style lang="scss" scoped>
::placeholder {
	color: #fff;
	text-transform: uppercase;
}
.wrapper {
	transition: 0.4s all;
	width: 54px;

	display: none; //TODO: Ripristinare una volta capito come

	.close-icon,
	.magnify {
		display: none;
	}
}
.wrapper.active {
	width: calc(100% + 40px);
	.close-icon,
	.magnify {
		display: block;
	}
}
.active .search-bar {
	margin-left: 40px;
}
.search-bar {
	font-size: 14px;
	width: calc(100% - 40px);
	cursor: pointer;
	border-bottom: 2px solid #fff;
	height: 37px;
	min-width: 47px;
	position: relative;
	input {
		width: 100%;
		border: 0;
		background: transparent;
		line-height: 37px;
		color: #fff;
		outline: 0 !important;
	}

	.search-icon {
		position: absolute;
		right: 7px;
		top: 50%;
		transform: translateY(-50%);
	}
	.close-icon,
	.magnify {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}
</style>
